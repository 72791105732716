<script setup>
import { ref } from 'vue';
import { Link } from '@inertiajs/vue3';
import Logo from '@/Components/Logo.vue';
import NavbarToggleButton from './NavbarToggleButton.vue';
import SearchBar from '@/Components/SearchBar.vue';

const showMobileNav = ref(false);
const toggleNavbar = () => {
    showMobileNav.value = !showMobileNav.value;
};
</script>

<template>
    <div class="flex flex-row items-center w-full h-20 pt-20 pb-6 text-white bg-white">
        <div class="w-1/3">
            <div class="w-2/3 mx-auto">
                <a href="/">
                    <Logo />
                </a>
            </div>
        </div>

        <div class="w-1/3">
            <div class="flex w-full">



            </div>
        </div>

        <div class="flex flex-row w-1/3">
            <div class="flex-row hidden w-full ml-6 text-black md:flex md:flex-row">

            </div>
            <div class="justify-end mt-2 ml-20 md:hidden">

            </div>
        </div>


    </div>
</template>
